import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth/context/AuthContext';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';

const CreditStatus = () => {
  const { user } = useAuth();
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      if (user) {
        const userRef = doc(db, 'usuarios', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setCredits(userDoc.data().credits || 0);
        }
      }
    };

    fetchCredits();
  }, [user]);

  if (credits === null) return null;

  return (
    <div className="bg-white/10 backdrop-blur-lg rounded-xl p-4 mb-4 text-white text-center">
      <div className="font-semibold">
        Crédits disponibles: {credits}
      </div>
      <div className="text-sm opacity-75">
        {user?.email}
      </div>
    </div>
  );
};

export default CreditStatus;
