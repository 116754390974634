import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../auth/context/AuthContext';
import { userTrackingService } from '../services/userTrackingService';
import CreditStatus from './CreditStatus';
import { useNavigate } from 'react-router-dom';

const VoiceChatFeature = () => {
  const { user } = useAuth(); // Removido refreshToken
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // Verificar autenticação ao carregar
  useEffect(() => {
    const checkAuth = async () => {
      if (!user?.token) {
        console.log('Token não encontrado. Prosseguindo sem refreshToken.');
        // Não faz nada, pois refreshToken não existe mais
      }
    };
    checkAuth();
  }, [user]);

  const startRecording = async () => {
    console.log('VoiceChatFeature: Iniciando gravação');
    try {
      // Verificar token antes de checar os créditos
      if (!user?.token) {
        console.log('Token não encontrado. Prosseguindo sem refreshToken.');
      }

      const userStatus = await userTrackingService.checkUserAttempts(user?.uid);
      if (!userStatus.canUse) {
        alert("Vos essais gratuits sont épuisés. Passez à la version premium pour continuer.");
        navigate('/pricing');
        return;
      }

      const constraints = {
        audio: {
          echoCancellation: {exact: true},
          mozNoiseSuppression: {exact: true},
          mozAutoGainControl: {exact: true},
          sampleRate: {ideal: 44100},
          channelCount: {ideal: 1}
        }
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/wav',
          audioBitsPerSecond: 128000
        });
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = async () => {
          console.log('VoiceChatFeature: Gravação finalizada, preparando upload');
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          await handleAudioUpload(audioBlob);
        };

        mediaRecorder.start();
        setIsRecording(true);
      } catch (err) {
        console.log('Tentando configuração básica');
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorder.onstop = async () => {
          console.log('VoiceChatFeature: Gravação finalizada, preparando upload');
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          await handleAudioUpload(audioBlob);
        };

        mediaRecorder.start();
        setIsRecording(true);
      }

    } catch (err) {
      console.error("Erreur d'accès au microphone:", err);
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        alert("Sur Safari iOS: Allez dans Réglages > Safari > Microphone et autorisez l'accès.");
      } else {
        alert("Erreur d'accès au microphone. Vérifiez les permissions.");
      }
    }
  };

  const stopRecording = () => {
    console.log('VoiceChatFeature: Parando gravação');
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  const handleAudioUpload = async (audioBlob) => {
    console.log('VoiceChatFeature: Iniciando upload de áudio');
    setLoading(true);

    try {
      if (!user?.uid) {
        throw new Error('Usuário não autenticado');
      }

      // Pegar token diretamente do Firebase
      const token = await user.getIdToken(true);
      console.log('Token obtido do Firebase:', !!token);

      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      const headers = {
        'Authorization': `Bearer ${token}`
      };
      console.log('Headers sendo enviados:', headers);

      const response = await fetch('https://frenchaudiochat.com/api/audio/upload', {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const data = await response.json();
      console.log('VoiceChatFeature: Dados recebidos do servidor:', data);

      if (data.success) {
        await userTrackingService.decrementCredits(user.uid);
        const userStatus = await userTrackingService.checkUserAttempts(user.uid);
        if (!userStatus.canUse) {
          alert("Vos essais gratuits sont épuisés. Passez à la version premium pour continuer.");
          navigate('/pricing');
          return;
        }

        const newMessages = [
          {
            type: 'user',
            content: data.transcription,
          },
          {
            type: 'correction',
            content: data.correction,
          },
          {
            type: 'system',
            content: data.systemResponse.transcription,
          }
        ];

        setMessages(prevMessages => [...prevMessages, ...newMessages]);
        console.log('VoiceChatFeature: Mensagens atualizadas');
      } else {
        throw new Error(data.error || 'Erreur inconnue');
      }
    } catch (error) {
      console.error('VoiceChatFeature: Erro de processamento de áudio:', error);
      alert('Un problème est survenu. Pour une meilleure expérience, assurez-vous que votre message ne dépasse pas 1 minute. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const renderMessage = (message, index) => {
    const messageTypes = {
      user: {
        className: "bg-blue-50",
        title: "Vous:"
      },
      correction: {
        className: "bg-yellow-50",
        title: "Correction:"
      },
      system: {
        className: "bg-gray-50",
        title: "Assistant:"
      }
    };

    const type = messageTypes[message.type];
    if (!type) return null;

    return (
      <div key={index} className={`p-4 rounded-lg ${type.className}`}>
        <div className="font-semibold mb-2">{type.title}</div>
        {message.type === 'correction' ? (
          <pre className="text-gray-800 whitespace-pre-wrap font-sans">{message.content}</pre>
        ) : (
          <div className="text-gray-800">{message.content}</div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-3xl mx-auto space-y-6">
      <CreditStatus />
      <div className="flex flex-col items-center">
        <p className="text-[10px] text-gray-500 mb-2">
          Cliquez pour commencer, cliquez à nouveau pour arrêter (1 minute max)
        </p>
        <button
          onClick={isRecording ? stopRecording : startRecording}
          className={`px-8 py-4 text-lg font-medium text-white rounded-full transition-all ${
            isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
          }`}
        >
          {isRecording ? "Arrêter l'enregistrement" : "Commencer l'enregistrement"}
        </button>
      </div>

      {loading && (
        <div className="text-gray-600">Traitement en cours...</div>
      )}

      <div className="w-full space-y-6">
        {messages.map((message, index) => renderMessage(message, index))}
      </div>

      <div className="w-full border-t border-gray-200 mt-4 p-2">
        <p className="text-[10px] text-center text-gray-500">
          Application en test, vous pouvez envoyer un message dans n'importe quelle language pour dire tout ce que vous avez vu, merci d'avance.<br/>
          Email: <a href="mailto:frenchaudiochat@gmail.com" className="text-blue-600 hover:underline">frenchaudiochat@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default VoiceChatFeature;
