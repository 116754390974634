import React from 'react';
import { useAuth } from '../context/AuthContext';
import firebaseAuth from '../services/firebaseAuth';

const GoogleLoginButton = ({ onSuccess: customOnSuccess }) => {
  const { login } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      const user = await firebaseAuth.loginWithGoogle();
      console.log('Firebase user:', user);
      await login(user);
      if (customOnSuccess) {
        customOnSuccess();
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <button
      onClick={handleGoogleLogin}
      className="flex items-center justify-center gap-2 bg-transparent text-white px-6 py-2 rounded-md hover:bg-white/10 border border-white/30 transition-all"
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google"
        className="w-6 h-6"
      />
      Se connecter avec Google
    </button>
  );
};

export default GoogleLoginButton;
