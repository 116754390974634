import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../../config/firebase';  // Corrigido o caminho
import { onAuthStateChanged } from 'firebase/auth';
import firebaseAuth from '../services/firebaseAuth';  // Adicionado import
const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  const login = async (user) => {
    setUser(user);
  };
  const logout = async () => {
    await firebaseAuth.logout();
    setUser(null);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
export default AuthContext;
