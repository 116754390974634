import React from 'react';
import { useAuth } from '../context/AuthContext';

const AuthGuard = ({ children, fallback }) => {
  const { user, loading, freeUseAvailable } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  // Permite acesso se usuário estiver autenticado ou tiver uso gratuito disponível
  if (user || freeUseAvailable) {
    return children;
  }

  // Se fornecido um fallback, mostra ele, senão mostra mensagem padrão
  if (fallback) {
    return fallback;
  }

  return (
    <div className="text-center p-4">
      <p className="text-yellow-300">
        Votre utilisation gratuite est terminée. Connectez-vous pour continuer à utiliser le service.
      </p>
    </div>
  );
};

export default AuthGuard;
