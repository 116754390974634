import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Mic, AudioLines, Globe } from 'lucide-react';
import VoiceChatFeature from './components/VoiceChatFeature';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './auth/context/AuthContext';
import { useAuth } from './auth/context/AuthContext';
import GoogleLoginButton from './auth/components/GoogleLoginButton';
import AuthGuard from './auth/components/AuthGuard';
import PricingPage from './components/PricingPage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './config/firebase';

// Componente da página inicial
const LandingPage = ({ onStart }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [credits, setCredits] = useState(null);

  useEffect(() => {
    const fetchCredits = async () => {
      if (user) {
        const userRef = doc(db, 'usuarios', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setCredits(userDoc.data().credits || 0);
        }
      }
    };

    fetchCredits();
  }, [user]);

  const handleStartClick = () => {
    if (!user) {
      alert("Veuillez vous connecter avec Google pour continuer");
      return;
    }
    onStart();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a237e] via-[#0d47a1] to-[#01579b] relative overflow-hidden">
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-96 h-96 bg-blue-400 rounded-full filter blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-indigo-400 rounded-full filter blur-3xl"></div>
      </div>
      <div className="relative z-10 container mx-auto px-4 py-20 text-center">
        <h1 className="text-4xl font-bold text-white mb-6">
          Bienvenue sur FrenchAudioChat
        </h1>
        <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
          Améliorez votre français en temps réel avec notre assistant IA intelligent.
          Parlez, écoutez et apprenez naturellement.
        </p>
        <div className="flex flex-col items-center space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Mic className="w-12 h-12 text-orange-400 mx-auto mb-4" />
              <h3 className="text-lg font-semibold text-white mb-2">Conversation Naturelle</h3>
              <p className="text-blue-100">Pratiquez votre français avec un assistant IA interactif</p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <AudioLines className="w-12 h-12 text-orange-400 mx-auto mb-4" />
              <h3 className="text-lg font-semibold text-white mb-2">Correction en Direct</h3>
              <p className="text-blue-100">Recevez des retours instantanés sur votre prononciation</p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Globe className="w-12 h-12 text-orange-400 mx-auto mb-4" />
              <h3 className="text-lg font-semibold text-white mb-2">Apprentissage Adaptatif</h3>
              <p className="text-blue-100">Progressez à votre rythme avec des exercices personnalisés</p>
            </div>
          </div>

          <div className="flex flex-col items-center space-y-4">
            <GoogleLoginButton onSuccess={onStart} />
          </div>

          <button
            onClick={handleStartClick}
            className="bg-orange-500 hover:bg-orange-600 text-white text-lg font-semibold px-8 py-4 rounded-full transition-all transform hover:scale-105 shadow-lg"
          >
            Commencer l'enregistrement
          </button>

          {user && (
            <p className="text-white/60 text-xs mt-2">
              {user.email} - {credits} crédits disponibles
            </p>
          )}
        </div>
      </div>
      <div className="w-full px-4 py-2 border-t border-white/10">
        <p className="text-[10px] text-center text-white/60">
          Application en test, vous pouvez envoyer un message dans n'importe quelle language pour dire tout ce que vous avez vu, merci d'avance. Email: <a href="mailto:frenchaudiochat@gmail.com" className="hover:underline">frenchaudiochat@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

// Componente interno que usa os hooks
const AppContent = () => {
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (!user) {
      return;
    }
    setStarted(true);
    navigate('/chat');
  };

  if (!started) {
    return <LandingPage onStart={handleStart} />;
  }

  return (
    <AuthGuard>
      <Routes>
        <Route path="/chat" element={<VoiceChatFeature />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="*" element={<Navigate to="/chat" replace />} />
      </Routes>
    </AuthGuard>
  );
};

// Componente principal que configura os providers
const App = () => (
  <Router>
    <GoogleOAuthProvider clientId="1083924288116-aj1kpresu1qmjrpe1qldv19eod738nke.apps.googleusercontent.com">
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </GoogleOAuthProvider>
  </Router>
);

export default App;
